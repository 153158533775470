import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface Parametrage {
  par_n_der_version: number
  par_n_codeark: number
  par_n_identifieurs: number
  wof_c_identifieur_1: string
  wof_c_identifieur_2: string
  wof_c_identifieur_3: string
  wof_c_identifieur_4: string
  wof_c_status_1: string
  wof_c_status_2: string
  wof_c_status_3: string
  wof_c_status_4: string
  wof_c_validation_1: string
  wof_c_validation_2: string
  wof_c_validation_3: string
  wof_c_validation_4: string
  wof_c_refus_1: string
  wof_c_refus_2: string
  wof_c_refus_3: string
  wof_c_refus_4: string
  par_c_identifiant_service: string
  par_c_code_service: string
  par_c_nom_service: string
  par_c_code_pays: string
  par_c_num_departement: string
  par_c_nom_departement: string
  par_c_ville: string
  par_c_region: string
  par_c_nom_responsable: string
  par_c_titre_responsable: string
  par_c_procedure_reglementaire: string
  par_x_client_bach: number
  par_n_genre_responsable: number
  par_c_status_juridique: string
  par_c_publisher: string
  par_c_adressline: string
  par_c_repository: string
  par_c_creation_text: string
  par_c_s3_region: string,
  par_c_s3_endpoint: string,
  par_c_s3_bucket: string,
  par_c_s3_accesskey: string,
  par_c_s3_secretkey: string,
  par_n_storage_local: number,
  par_n_storage_s3: number,
  par_n_s3_use_ssl: number,
  par_n_s3_max_keys: number,
  par_c_s3_root_path: string,
  par_n_s3_force_path_style: number
}

interface StoreInfo {
  parametrage: Parametrage;
}

@Module
export default class ParametrageModule extends VuexModule implements StoreInfo {
  parametrage = {} as Parametrage;

  /**
   * Get parametrage object for current page
   * @returns object
   */
  get getParams(): Parametrage {
    return this.parametrage;
  }

  @Mutation
  [Mutations.SET_PARAMETRAGE_MUTATION](payload) {
    this.parametrage = payload;
  }

  @Action
  [Actions.SET_PARAMETRAGE_ACTION](payload) {
    this.context.commit(Mutations.SET_PARAMETRAGE_MUTATION, payload);
  }
}
