
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { getAxios } from "./router";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();

    onMounted(async () => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      if(window.localStorage.getItem('codeArk') && !store.getters.getParams.par_n_codeark){
        const parametrage = await getAxios("/getParametrage")
        store.dispatch(Actions.SET_PARAMETRAGE_ACTION, parametrage.results);
      }
    });
  },
});
